import { createContext, useContext, useReducer } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDocs,
  collection,
  addDoc,
} from "firebase/firestore/lite";
import StoreReducer from "./StoreReducer";

import actions from "./Actions";
import { initialFirebaseContext } from "../Confige/Types";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import Swal from "sweetalert2";
const {
  GET_PROJECTS_DATA,
  GET_Client_DATA,
  GET_Faq_DATA,
  GET_Info_DATA,
  SIGN_IN_INFO,
  SAVE_IN_INFO,
} = actions;
//firebase project link
const firebaseConfig =
  process.env.NODE_ENV === "development"
    ? {
        apiKey: "AIzaSyAGI6o0meI150e6cRxpwBOE1YYsPQa5iX0",
        authDomain: "testing-88c6b.firebaseapp.com",
        projectId: "testing-88c6b",
        storageBucket: "testing-88c6b.appspot.com",
        messagingSenderId: "133075129745",
        appId: "1:133075129745:web:315bf14b95417f4040f630",
      }
    : {
        apiKey: "AIzaSyDSrHg3oHFXGgQgjb3Q9lVp0gNf2bhU_Ts",
        authDomain: "hmzones-6eaac.firebaseapp.com",
        databaseURL: "https://hmzones-6eaac-default-rtdb.firebaseio.com",
        projectId: "hmzones-6eaac",
        storageBucket: "hmzones-6eaac.appspot.com",
        messagingSenderId: "501137843306",
        appId: "1:501137843306:web:8c5d49605bc72f60d9d613",
        measurementId: "G-W0DN451T3M",
      };
const firebaseApp = initializeApp(firebaseConfig);
export const useFirebase = () => useContext(FirebaseContext);
export const auth = getAuth(firebaseApp);
const initialContext: initialFirebaseContext = {
  projectsData: [],
  clientData: [],
  FaqData: [],
  InfoData: [],
  userInfo: JSON.parse(localStorage.getItem("userInfo") || "null"),
  getProjectData: () => {},
  getClientData: () => {},
  getFaqData: () => {},
  getInfoData: () => {},
  signinUser: () => {},
  SaveInfo: (data: any) => {},
  getAuthInfo: () => {},
  logoutUser: () => {},
};
export const FirebaseContext = createContext(initialContext);

export const FirebaseProvider = (props: any) => {
  const [state, dispatch] = useReducer(StoreReducer, initialContext);
  //Page Validation for user authentication
  const getAuthInfo = () => {
    const data = onAuthStateChanged(auth, (user: any) => {
      if (user) {
        return user;
      } else {
        // User is logged out
        return null;
      }
    });
    return data;
  };

  //Log out user
  const logoutUser = () => {
    signOut(auth);
    dispatch({ payload: null, type: SIGN_IN_INFO });
    localStorage.setItem("userInfo", JSON.stringify(""));
  };

  //Sign In User
  const signinUser = (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((value) => {
        dispatch({ payload: value, type: SIGN_IN_INFO });
        localStorage.setItem("userInfo", JSON.stringify(value));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Valid Email",
          showConfirmButton: false,
          timer: 1000,
        });
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "In-Valid Email",
          showConfirmButton: true,
        });
      });
  };

  //Save Info Data
  const SaveInfo = (data: any) => {
    const userCollectionRef = collection(db, "contactdata");
    return addDoc(userCollectionRef, data)
      .then((value) => {
        dispatch({ payload: value, type: SAVE_IN_INFO });
        Swal.fire("Good job!", "Your data has been submitted!", "success");
        return true;
      })
      .catch((error) => {
        alert(error?.message);
        return false;
      });
  };

  //Projects data
  const getProjectData = async () => {
    const data = await getDocs(
      collection(getFirestore(firebaseApp), "projects")
    );
    const newData: any = [];
    data.forEach((obj) => newData.push(obj.data()));

    dispatch({
      payload: newData,
      type: GET_PROJECTS_DATA,
    });
  };

  //Clients Reviw data
  const getClientData = async () => {
    const clientdata = await getDocs(
      collection(getFirestore(firebaseApp), "clientReview")
    );
    const clientnewData: any = [];
    clientdata.forEach((obj) => clientnewData.push(obj.data()));

    dispatch({
      payload: clientnewData,
      type: GET_Client_DATA,
    });
  };

  //Faq Data
  const getFaqData = async () => {
    const Faqdata = await getDocs(
      collection(getFirestore(firebaseApp), "faqs")
    );
    const FaqnewData: any = [];
    Faqdata.forEach((obj) => FaqnewData.push(obj.data()));

    dispatch({
      payload: FaqnewData,
      type: GET_Faq_DATA,
    });
  };

  //Client Info Data
  const getInfoData = async () => {
    const Infodata = await getDocs(
      collection(getFirestore(firebaseApp), "contactdata")
    );
    const InfonewData: any = [];
    Infodata.forEach((obj) => InfonewData.push(obj.data()));
    dispatch({
      payload: InfonewData,
      type: GET_Info_DATA,
    });
  };
  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        getProjectData,
        getClientData,
        getFaqData,
        getInfoData,
        getAuthInfo,
        signinUser,
        SaveInfo,
        logoutUser,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};
//previous project method
export const db = getFirestore(firebaseApp);
export const app = initializeApp(firebaseConfig);
