import React, { Component } from 'react';

class Aboutus extends Component {
    render() {
        return (
            <div>
               <h3>aboutus</h3> 
            </div>
        );
    }
}
export default Aboutus;