import React, { FC, ReactElement, useContext } from "react";
import { FirebaseContext } from "../../context/Firebase";
import { Navigate } from "react-router-dom";
const PrivateRoute: FC<{
  children: ReactElement;
}> = ({ children }) => {
  const { userInfo } = useContext(FirebaseContext);

  return userInfo?<>{children}</>:<Navigate to="/signin" replace={true} />
};

export default PrivateRoute;
