import React, {
  FC,
  ReactElement,
  useContext,
} from "react";
import { FirebaseContext } from "../../context/Firebase";
import { Navigate } from "react-router-dom";

const PublicRoute: FC<{
  children: ReactElement;
}> = ({ children }) => {
  const { userInfo } = useContext(FirebaseContext);

  return userInfo ? <Navigate to="/client" replace={true}/> : <>{children}</>;
};

export default PublicRoute;
