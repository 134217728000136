import React, { memo } from "react";
import ClientInfoCard from "./Utilities/ClientInfoCard";
import Container from "./Utilities/Container";
import { useContext, useEffect } from "react";
import { FirebaseContext } from "../context/Firebase";
import { InfoProps } from "../Confige/Types";

const ClientInfo = memo(() => {
  const { InfoData, getInfoData,logoutUser } = useContext(FirebaseContext);
  useEffect(() => {
    getInfoData();
  }, []);
  return (
    <div className="min-h-screen w-full bg-hmbase">
      <Container>
        <>
          <div className="flex justify-end py-6">
            <button
              onClick={() => logoutUser()}
              className=" bg-green-600 hover:bg-red-600 p-3 rounded-lg text-lg font-bold text-slate-100"
            >
              Log Out
            </button>
          </div>

          <div className="pb-6">
            <h2 className="text-[#214B4D] text-3xl sm:text-4xl md:text-5xl font-bold text-center ">
              Client Data
            </h2>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="w-full border rounded-lg">
                  <table className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          Contact No.
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                        >
                          Message
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                        >
                          date
                        </th>
                      </tr>
                    </thead>
                    {(InfoData || []).map((obj: InfoProps) => (
                      <ClientInfoCard
                        key={obj.id}
                        name={obj.name}
                        email={obj.email}
                        phone={obj.phone}
                        message={obj.message}
                        date={obj.date}
                      />
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      </Container>
    </div>
  );
});

export default ClientInfo;
