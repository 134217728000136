import React, { FC, memo } from "react";

const ClientInfoCard: FC<{
  name: string;
  email?: string;
  phone: string;
  message?: string;
  date?:string;
}> = memo(({ name, email, phone, message,date }) => {
  return (
    <tbody className="divide-y divide-gray-200 bg-gray-100">
      <tr>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {name}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {email}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {phone}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {message}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {date}
        </td>
      </tr>
    </tbody>
  );
});

export default ClientInfoCard;
