import React, { memo } from 'react'

const Admin = memo(() => {
  return (
    <>
      <h1>Admin</h1>
      {/* <button onClick={()=> app.auth().signOut()}>sign out</button> */}
    </>
  )
})

export default Admin;