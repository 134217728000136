import { Routes, Route } from "react-router-dom";
import Aboutus from "./pages/Aboutus";
import Contactus from "./pages/Contactus";
import SignIn from "./pages/SignIn";
import Admin from "./pages/Admin";
import React, { lazy, Suspense, useState, useEffect } from "react";
import Preloader from "./components/Preloader";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./context/Firebase";
import ClientInfo from "./components/ClientInfo";
import PublicRoute from "./components/Utilities/PublicRoute";
import PrivateRoute from "./components/Utilities/PrivateRoute";
const auth = getAuth(app);
const Home = lazy(() => import("./pages/Home"));
const App = () => {
  // for user authentication
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        //Yes, you are logged in
        setUser(user);
      } else {
        // User is logged out
        setUser(null);
      }
    });
  }, []);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Preloader />}>
            <Home />
          </Suspense>
        }
      />

      <Route path="/aboutus" element={<Aboutus />} />
      <Route path="/contactus" element={<Contactus />} />
      <Route
        path="/SignIn"
        element={
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        }
      />
      <Route path="/Admin" element={<Admin />} />
      <Route
        path="/client"
        element={
          <PrivateRoute>
            <ClientInfo />
          </PrivateRoute>
        }
      />

      <Route
        path="*"
        element={
          <div className="bg-[#45ACB1] h-screen">
            <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
              <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div className="max-w-md text-center">
                  <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
                    <span className="sr-only">Error</span>404
                  </h2>
                  <p className="text-2xl font-semibold md:text-3xl">
                    Sorry, we couldn't find this page.
                  </p>
                  <p className="mt-4 mb-8 dark:text-gray-400">
                    But dont worry, you can find plenty of other things on our
                    homepage.
                  </p>
                  <a
                    rel="noopener noreferrer"
                    href="/"
                    className="px-8 py-3 font-bold rounded bg-red-600 hover:bg-green-600 text-lg"
                  >
                    Back to homepage
                  </a>
                </div>
              </div>
            </section>
          </div>
        }
      />
    </Routes>
  );
};
export default App;
