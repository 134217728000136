import { memo, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { IoLogoYoutube } from "react-icons/io";
import { FirebaseContext } from "../context/Firebase";
const SignIn = memo(() => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signinUser } = useContext(FirebaseContext);
  return (
    <>
      <div className="contain">
        <div className="main">
          <div className="sign">
            <p>Sign In</p>
            <div className="flex flex-row w-full justify-evenly items-center text-white">
              <Link to="https://www.facebook.com/HMZones1">
                <BsFacebook className="h-6 w-6" />
              </Link>
              <Link to="https://twitter.com/HmZones">
                <BsTwitter className="h-6 w-6" />
              </Link>
              <Link to="https://www.instagram.com/hmzones/">
                <AiFillInstagram className="h-6 w-6" />
              </Link>
              <Link to="https://www.linkedin.com/company/hm-zones/">
                <BsLinkedin className="h-6 w-6" />
              </Link>
              <Link to="/">
                <IoLogoYoutube className="h-6 w-6" />
              </Link>
            </div>
          </div>
          <div className="box">
            <div className="form">
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                placeholder="@gmail.com"
                autoComplete="off"
                required
              />
              <br />
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Current Password"
                autoComplete="off"
                required
              />
              <div className="lab">
                <input type="checkbox" id="switch" />
                <label>Toggle</label>
                <p>Remember me</p>
              </div>
              <a>
                <button
                  className="btn"
                  id="insert"
                  onClick={() => signinUser(email, password)}
                >
                  SIGN IN
                </button>
              </a>
              <div className="signup">
                <p>
                  Back To
                  <a href="/" className=" cursor-pointer">
                    <b> Home Page</b>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default SignIn;
