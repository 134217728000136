import { FC, memo } from 'react';
import SampleImg from '../../assets/images/download.png'
interface ImageProps {
    className?: string,
    alt?: string,
    src?: string,
}
const Image: FC<ImageProps> = memo(({ className, alt, src }) => {
    return (
        <img src={src} alt={alt} className={className} title={alt} />
    );
});

Image.defaultProps = {
    src: SampleImg,
    alt: "SampleImg",
    className: "w-10 h-10"
}

export default Image;