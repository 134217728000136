import React, { memo } from 'react'
import Image from "./Utilities/Image";
import logo from "../assets/images/HMZonesPreloader.svg";

const Preloader = memo(() => {
  return (
    <div className=' bg-hmbase flex w-full h-screen justify-center items-center'>
        <Image src={logo} alt="HMZones" className=' w-72'/>  
    </div>
  )
})
export default Preloader